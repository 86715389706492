<template>
  <div class="my-box">
    <!-- 功能区域 -->
    <el-row>
      <el-card shadow="always">
        <el-form label-width="50px" class="topS">
          <el-row :gutter="10">
            <el-col :span="3">
              <el-button @click="addBtn" v-if="showadd != -1">新增</el-button>
              <el-button @click="batchesDelete" v-if="showdelete != -1"
                >批量删除</el-button
              >
            </el-col>
            <el-col :span="6">
              <el-form-item label="品牌">
                <el-input
                  placeholder="请输入内容"
                  v-model="seekData.brandName"
                  clearable
                  class="my-input"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="15" class="reset-button">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button @click="reset">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </el-row>
    <!-- 内容区域 -->
    <el-row>
      <el-card shadow="always" v-loading="tabloading">
        <div class="my-header-table">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            :height="height"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column
              prop="brandName"
              label="品牌名称"
            ></el-table-column>
            <el-table-column
              prop="createUser"
              label="创建用户"
            ></el-table-column>
            <el-table-column
              prop="createTime"
              label="创建时间"
              sortable
            ></el-table-column>
            <!-- <el-table-column prop="description" label="品牌介绍"></el-table-column> -->

            <!-- 操作按钮列 -->
            <el-table-column label="操作" width="350">
              <template slot-scope="scope">
                <el-button
                  v-if="showwifi != -1"
                  type="success"
                  size="mini"
                  dialogFormVisible
                  @click="wifiMsg(scope.row)"
                >
                  wifi设置
                </el-button>
                <!-- 编辑按钮 -->
                <el-button
                  v-if="showupdate != -1"
                  type="primary"
                  size="mini"
                  dialogFormVisible
                  @click="handleEdit(scope.row)"
                >
                  修改
                </el-button>
                <!-- 删除按钮 -->
                <el-button
                  v-if="showdelete != -1"
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            layout="prev, pager, next, sizes, total, jumper"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="seekData.pageSize"
            :current-page="seekData.pageNum"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </div>
      </el-card>
    </el-row>

    <!-- 新增的弹框 -->
    <el-dialog
      :title="form.id ? '修改品牌' : '新增品牌'"
      :visible.sync="dialogFormVisible"
    >
      <el-form :model="form" ref="form" :rules="myrules" label-width="120px">
        <el-form-item label="名称" prop="brandName">
          <el-input
            v-model.trim="form.brandName"
            placeholder="请输入品牌名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="logo" prop="logo">
          <el-upload
            class="avatar-uploader"
            :action="imgUploadUrl + 0"
            :headers="tokenHeaders"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            accept="image/*"
          >
            <img v-if="form.logo" :src="form.logo" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="描述">
          <el-input
            type="textarea"
            clearable
            :rows="5"
            placeholder="请输入内容"
            v-model="form.description"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="abrogateAdd">取 消</el-button>
        <el-button
          type="primary"
          @click="submitFormAdd('form')"
          :loading="loading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 新增的弹框 -->
    <el-dialog title="wifi信息设置" :visible.sync="wifiDiaolog" width="60%">
      <el-form
        :model="wifiForm"
        ref="wifiForm"
        :rules="myrules"
        label-width="120px"
      >
        <el-form-item label="名称">
          {{ wifiForm.brandName }}
        </el-form-item>
        <el-form-item label="wifi账号" prop="wifiSsid">
          <el-input
            v-model.trim="wifiForm.wifiSsid"
            placeholder="请输入wifi账号"
          ></el-input>
        </el-form-item>
        <el-form-item label="wifi密码" prop="wifiPassword">
          <el-input
            v-model.trim="wifiForm.wifiPassword"
            placeholder="请输入wifi密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="覆盖到酒店">
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <div style="margin: 15px 0"></div>
          <el-checkbox-group
            v-model="wifiForm.hotelIdList"
            @change="handleCheckedCitiesChange"
          >
            <el-checkbox
              class="checkBox"
              v-for="(item, index) in hotelSelectDatas"
              :label="item.id"
              :key="index"
              >{{ item.name }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="abrogateAdd">取 消</el-button>
        <el-button
          type="primary"
          @click="submitWifiForm('wifiForm')"
          :loading="loading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  addBrand,
  delBrand,
  getHotelSelect,
  getBrandSelect,
  getBrandSeek,
  editBrand,
  brandNameVerify,
  wifiUpdate,
  imgUploadUrl,
} from "@/api";

import store from '../../../vuex/index'

export default {
  // name:"pp",
  data() {
    /* 品牌名是否重复校验 */
    let Verify = (rule, value, callback) => {
      if (value === null || value === "") {
        callback(new Error("不能为空"));
      } else {
        brandNameVerify({ brandName: value, id: this.form.id }).then((res) => {
          if (res.data.data) {
            callback();
          } else {
            callback(new Error("你输入的已存在，请重新输入"));
          }
        });
      }
    };
    return {
      imgUploadUrl: imgUploadUrl,
      tokenHeaders: {
        Authentication: store.state.token
      },
      multipleSelection: [],
      loading: false,
      tabloading: false,

      // 品牌列表数据
      tableData: [],
      dialogFormVisible: null,
      wifiDiaolog: false,
      // 查询的数据
      total: null,
      seekData: {
        brandName: null,
        pageSize: 10,
        pageNum: 1,
      },
      hotelSelectDatas: [],
      // 编辑
      form: {
        brandName: null,
        description: null,
        logo: null,
        id: null,
      },
      wifiForm: {
        brandName: null,
        wifiSsid: null,
        wifiPassword: null,
        id: null,
        hotelIdList: [],
      },
      checkAll: false,
      isIndeterminate: false,
      myrules: {
        brandName: [{ required: true, validator: Verify, trigger: "blur" }],
        wifiSsid: [{ required: true, message: "请输入", trigger: "blur" }],
        logo: [{ required: true, message: "请上传图片", trigger: "blur" }],
        wifiPassword: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            min: 8,
            max: 15,
            message: "长度在 8 到 15 个字符",
            trigger: "blur",
          },
        ],
      },
      userJurisdiction: null,
    };
  },
  beforeMount() {
    let h = document.documentElement.clientHeight || document.body.clientHeight;
    this.height = h - 240;
  },

  computed: {
    // 权限
    showadd: function () {
      return this.userJurisdiction.indexOf("brand:add");
    },
    showdelete: function () {
      return this.userJurisdiction.indexOf("brand:delete");
    },
    showupdate: function () {
      return this.userJurisdiction.indexOf("brand:update");
    },
    showwifi: function () {
      return this.userJurisdiction.indexOf("wifi:update");
    },
  },
  created() {
    this.userJurisdiction = this.$store.state.roleMenu;
    // 调用初始化表格数据方法
    this.getList();
  },
  methods: {
    handleAvatarSuccess(res, file) {
      if (res.code == "000000" && res.data) {
        this.form.logo = res.data.path;
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type.indexOf("image/") !== -1;
      if (!isJPG) {
        this.$message.error("只能上传图片");
      }
      return isJPG;
    },

    handleCheckAllChange(val) {
      console.log(val);
      let arr = [];
      if (val) {
        arr = this.hotelSelectDatas.map((item) => item.id);
      }
      this.wifiForm.hotelIdList = arr;
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      console.log(value);
      let checkedCount = value.length;
      this.checkAll =
        this.hotelSelectDatas.length &&
        checkedCount === this.hotelSelectDatas.length;
      this.isIndeterminate =
        checkedCount > 0 &&
        this.hotelSelectDatas.length &&
        checkedCount < this.hotelSelectDatas.length;
    },
    wifiMsg(row) {
      ["brandName", "id", "wifiSsid", "wifiPassword"].forEach((key) => {
        this.wifiForm[key] = row[key];
      });
      this.wifiForm.hotelIdList = [];
      this.selectOnes(row.id);
      this.wifiDiaolog = true;
    },
    // 选择框
    selectOnes(id) {
      getHotelSelect(id).then((res) => {
        this.hotelSelectDatas = res.data.data;
        // this.wifiForm
        if (this.wifiForm.wifiSsid && this.wifiForm.wifiPassword) {
          this.hotelSelectDatas.forEach((item) => {
            if (
              item.wifiSsid == this.wifiForm.wifiSsid &&
              item.wifiPassword == this.wifiForm.wifiPassword
            ) {
              this.wifiForm.hotelIdList.push(item.id);
            }
          });
          // if(this.wifiForm.hotelIdList.length)
        }
        this.handleCheckedCitiesChange(this.wifiForm.hotelIdList);
        console.log(this.hotelSelectDatas);
      });
    },
    submitWifiForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {};
          data.brandId = this.wifiForm.id;
          data.wifiSsid = this.wifiForm.wifiSsid;
          data.wifiPassword = this.wifiForm.wifiPassword;
          data.hotelIdList = this.wifiForm.hotelIdList;
          // data.hotelIdList=this.wifiForm.hotelIdList
          console.log(this.wifiForm);

          this.wifiUpdateReq(data);
        } else {
          return false;
        }
      });
    },
    wifiUpdateReq(data) {
      this.loading = true;
      wifiUpdate(data)
        .then((res) => {
          console.log(res);
          this.loading = false;
          if (res.data.code == "000000") {
            this.$message.success("设置成功");
            this.wifiDiaolog = false;
          } else {
            this.$message.error("设置失败");
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error("设置失败!请联系管理员");
        });
    },
    // 初始化表格数据
    getList() {
      this.tabloading = true;
      getBrandSeek(this.seekData)
        .then((res) => {
          this.tabloading = false;
          if (res.status === 200) {
            this.tableData = res.data.data.rows;
            this.total = res.data.data.total;
          }
        })
        .catch((err) => {
          this.tabloading = false;
        });
    },
    initialize() {
      getBrandSelect().then((res) => {
        // console.log(res)
        if (res.status === 200) {
          this.brandSelectData = res.data.data;
        }
      });
    },

    // 新增弹窗
    addBtn() {
      this.dialogFormVisible = true;
      if (this.form.id) {
        this.initForm();
      }
    },
    initForm() {
      this.form = {
        brandName: null,
        description: null,
        id: null,
      };
    },
    // 确定按钮
    submitFormAdd(formName) {
      // console.log(this.addform)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {};
          data.brandName = this.form.brandName;
          data.description = this.form.description;
          data.logo = this.form.logo;
          if (this.form.id) {
            data.id = this.form.id;
            this.editReq(data);
          } else {
            this.addReq(data);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    addReq(data) {
      this.loading = true;
      addBrand(data)
        .then((res) => {
          console.log(res);
          this.loading = false;
          if (res.data.code == "000000") {
            this.$message.success(res.data.message);
            this.initForm();
            this.getList();
            this.dialogFormVisible = false;
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    editReq(data) {
      this.loading = true;
      editBrand(data)
        .then((res) => {
          this.loading = false;
          if (res.data.code == "000000") {
            this.$message.success(res.data.message);
            this.getList();
            this.initForm();
            this.dialogFormVisible = false;
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    // 取消按钮
    abrogateAdd() {
      this.dialogFormVisible = false;
    },

    // 删除品牌
    handleDelete(row) {
      // console.log(row)
      this.$confirm("此操作将永久删除该品牌, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delReq(row.id);
        })
        .catch(() => {});
    },
    // 批量删除
    batchesDelete() {
      if (this.multipleSelection.length != 0) {
        // 把要删除的用户ID以字符串拼接
        let number = "";
        for (let i = 0; i < this.multipleSelection.length; i++) {
          const element = this.multipleSelection[i];
          number += element.id + ",";
        }
        number = number.slice(0, number.length - 1); //去掉最后的逗号
        this.$confirm("此操作将永久删除所选择品牌, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.delReq(number);
          })
          .catch(() => {});
      } else {
        this.$message.warning("请先选择要删除的数据");
      }
    },
    delReq(id) {
      this.loading = true;
      delBrand(id)
        .then((res) => {
          this.loading = false;
          if (res.data.code == "000000") {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.error("删除失败");
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 编辑品牌
    handleEdit(index, row) {
      console.log(index);
      this.form = JSON.parse(JSON.stringify(index));
      //  this.form.brandName = index.brandName;
      //  this.form.description = index.description;
      //  this.form.id = index.id;
      this.dialogFormVisible = true;
    },

    // 查询按钮
    handleSearch() {
      if (this.seekData.brandName) {
        this.seekData.pageNum = 1;
      }
      this.getList();
    },
    // 重置按钮
    reset() {
      this.seekData.brandName = null; //品牌
      this.seekData.pageNum = 1;
      this.getList();
    },
    //分页
    handleCurrentChange(pageNum) {
      this.seekData.pageNum = pageNum;
      this.getList();
    },
    handleSizeChange(psize) {
      this.seekData.pageSize = psize;
      this.seekData.pageNum = 1;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload--text {
  text-align: center;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  height: 178px !important;
  line-height: 178px !important;
  text-align: center;
}
.avatar {
  max-width: 100%;
  max-height: 100%;
  // height: 178px;
  // display: block;
}
.my-box {
  .el-row {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .my-input {
    max-width: 209px;
  }
}
.checkBox {
  display: inline-block;
  width: 28%;
}
</style>
